.layout-topbar {
    padding: 0;
    height: 5rem;
    position: fixed;
    top: 0;
    left: 250px;
    width: calc(100% - 250px);
    z-index: 1100;
    display: flex;
    align-items: center;
    padding: 0 4rem;

    &.layout-topbar-sticky {
        backdrop-filter: blur(12px);
    }

    .menu-button {
        display: none;
        color: var(--text-color);
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        transition: background-color .2s;
        cursor: pointer;

        &:hover {
            background-color: var(--surface-hover);
        }

        i {
            font-size: 24px;
            line-height: inherit;
        }
    }

    .logo {
        &:focus {
            outline: 0 none;
            transition: box-shadow .2s;
            box-shadow: var(--focus-ring);
        }
    }

    .app-theme {
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        padding: .5rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        width: 39px;
        height: 39px;

        img {
            width: 25px;
        }
    }

    .topbar-menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-left: auto;
        display: flex;

        > li {
            margin-right: .5rem;

            > a,
            > .p-link {
                font-weight: 500;
                text-decoration: none;
                color: var(--text-color);
                padding: .5rem 1.5rem;
                display: block;
                text-align: center;
                user-select: none;
                border-radius: 10px;
                cursor: pointer;
                transition: background-color .3s;

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: var(--focus-ring);
                }

                &:hover {
                    background-color: var(--surface-hover);
                }
            }

            &.topbar-submenu {
                position: relative;

                > ul {
                    position: absolute;
                    transform-origin: top;
                    top: 3rem;
                    right: 0;
                    width: 275px;
                    max-height: 400px;
                    background-color: var(--surface-overlay);
                    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
                    overflow: auto;
                    list-style-type: none;
                    padding: 1rem;
                    margin: 0;
                    border-radius: 10px;

                    > li {
                        line-height: 1;

                        &.topbar-submenu-header {
                            display: block;
                            color: var(--text-color-secondary);
                            font-weight: 600;
                            user-select: none;
                            padding: 1.5rem 0 1rem 0;
                            font-size: 0.857rem;
                            text-transform: uppercase;

                            &:first-child {
                                padding-top: 1rem;
                            }
                        }
                    }

                    a,
                    .p-link {
                        text-decoration: none;
                        color: var(--text-color);
                        padding: .5rem;
                        display: flex;
                        align-items: center;
                        user-select: none;
                        border-radius: 10px;
                        cursor: pointer;
                        width: 100%;

                        &:hover {
                            background-color: var(--surface-hover);
                        }

                        span {
                            margin-left: .5rem;
                        }

                        i {
                            font-size: 18px;
                            color: var(--text-color-secondary);
                        }

                        img {
                            width: 32px;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }
    }

    .theme-badge {
        padding: 2px 4px;
        vertical-align: middle;
        border-radius: 3px;
        color: #ffffff;
        font-weight: bold;
        font-size: 11px;
        position: relative;
        top: -1px;
    }

    .theme-badge.material {
        background: linear-gradient(to bottom, #2196F3, #2196F3);
    }

    .theme-badge.bootstrap {
        background: linear-gradient(to bottom, #563D7C, #966BD8);
    }

    .theme-badge.darkmode {
        background: linear-gradient(to bottom, #141d26, #5a6067);
    }
}


.layout-wrapper-dark {
    .layout-topbar {
        &.layout-topbar-sticky {
            background-color: rgba(0, 0, 0 , 0.3);
        }
    }
}

.layout-wrapper-light {
    .layout-topbar {
        &.layout-topbar-sticky {
            background-color: rgba(255, 255, 255, 0.85);
        }
    }
}