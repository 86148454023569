.layout-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .layout-content {
        margin-left: 250px;
        padding-top: 70px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .content-section {
            padding: 2rem 4rem;
            flex-grow: 1;

            &.introduction {
                color: var(--text-color);
                padding-bottom: 0;
                display: flex;
                align-items: top;
                justify-content: space-between;
    
                .feature-intro {
                    h1 {
                        color: var(--surface-900);
                    }
    
                    p {
                        margin: 0;
                        font-size: 1.25rem;
                    }
    
                    a {
                        text-decoration: none;
                        color: $linkColor;
                        font-weight: 600;
    
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
    
                .app-demoactions {
                    .p-button-icon-only {
                        padding: .5rem 0;
                    }
                }
            }
    
            &.implementation {
                color: var(--text-color);
    
                +.documentation {
                    padding-top: 0;
                }
            }
    
            &.documentation {
                color: var(--text-color);

                h1 {
                    color: var(--surface-900);
                }
    
                li {
                    line-height: 1.5;
                }
    
                a {
                    text-decoration: none;
                    color: $linkColor;
                    font-weight: 600;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
    
                .doc-tablewrapper {
                    margin: 1rem 0;
                    overflow: auto;
                    border-radius: 10px;
                }
    
                i:not([class~="pi"]) {
                    background-color: var(--surface-card);
                    color: #2196f3;
                    font-family: Monaco, courier, monospace;
                    font-style: normal;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 2px 4px;
                    letter-spacing: .5px;
                    border-radius: 3px;
                    font-weight: 600;
                    margin: 0 2px;
                }
    
                .p-tabview {
                    background: transparent;
                    border: 0 none;
    
                    .p-tabview-nav {
                        border-radius: 0;
                        padding: 0;
                        border-bottom: 1px solid var(--surface-border);
                        background-color: transparent;
    
                        li {
                            margin-right: 0;
                            border: 0 none;
                            top: 1px;
                            line-height: 1;
    
                            a, a:visited {
                                color: var(--text-color-secondary);
                                text-shadow: none;
                                height: inherit;
                                background-color: transparent;
                                border: 0 none;
                                border-bottom: 1px solid transparent;
                                margin-bottom: -1px;
                                transition: border-bottom-color .2s;
    
                                &:focus {
                                    outline: 0 none;
                                    transition: background-color .2s, box-shadow .2s;
                                    box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                                }
    
                                &:hover {
                                    background: transparent;
                                    text-decoration: none;
                                }
                            }
    
                            &.p-highlight a,
                            &.p-highlight:hover a {
                                background: transparent;
                                color: var(--primary-color);
                                border-bottom: 1px solid var(--primary-color);
                            }
    
                            &:not(.p-highlight):not(.p-disabled):hover a {
                                color: var(--text-color);
                                border-bottom: 1px solid var(--primary-color);
                            }
                        }
    
                        .p-tabview-ink-bar {
                            display: none;
                        }
                    }
    
                    .p-tabview-panels {
                        background: transparent;
                        border: 0 none;
                        padding: 2rem 1rem;
    
                        .btn-viewsource {
                            display: inline-block;
                            padding: .5rem 1rem;
                        }
    
                        a {
                            text-decoration: none;
                            color: $linkColor;
                            font-weight: 600;
    
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
    
            .doc-table {
                border-collapse: collapse;
                width: 100%;
                background-color: var(--surface-card);
    
                th {
                    border-bottom: 1px solid var(--surface-border);
                    padding: 1rem;
                    text-align: left;
                    white-space: nowrap;
                }
    
                tbody {
                    tr:hover {
                        background: var(--surface-hover);
                    }
    
                    td {
                        padding: 1rem;
                        border-bottom: 1px solid var(--surface-border);
    
                        &:first-child {
                            font-family: ui-monospace,SFMono-Regular,"SF Mono",Menlo,Consolas,Liberation Mono,monospace;
                            font-weight: 700;
                            color: var(--text-color-secondary);
                        }
                    }
                }
    
                &.browsers {
                    td {
                        &:first-child {
                            font-family: var(--font-family);
                            font-weight: normal;
                            color: var(--text-color);
                        }
                    }
                }
            }
        }
    
        .card {
            background: var(--surface-card);
            padding: 2rem;
            border-radius: 10px;
            margin-bottom: 2rem;
    
            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
