/* Home Component*/
.home {
    a {
        text-decoration: none;
        color: $linkColor;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        line-height: 1.5;
    }

    p {
        margin-bottom: 2rem;
    }

    .section-image {
        width: 100%;
        max-width: 430px;
    }

    .introduction {
        background-color: var(--surface-a);
        // background-image: url('../../images/home/intro-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 115px 30px 135px 50px;
        color: #455C71;
        position: relative;

        &.introduction-dark {
            // background-image: url('../../images/home/intro-bg-dark.jpg');
            color: var(--text-color);

            .introduction-promo {
                background-image: linear-gradient(90deg, #445C72 0%, transparent 100%);
            }
        }

        .introduction-promo {
            display: inline-block;
            margin-left: -50px;
            margin-bottom: 20px;
            padding: 10px 175px 10px 50px;
            font-size: 1.5rem;
            background-image: linear-gradient(90deg, #445C72 0%, transparent 100%);
            position: relative;
            color: #ffffff;
            font-weight: 700;

            img {
                position: absolute;
                height: 75px;
                left: 50px;
                top: -12px;
            }
        }

        .introduction-title {
            font-weight: 400;
            margin-bottom: 5px;
            font-size: 24px;
        }

        .introduction-subtitle {
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 0;
            font-size: 24px;
        }

        .introduction-devices {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 60%;
            max-width: 1080px;
        }
    }

    .features {
        background-color: var(--surface-b);
        text-align: center;
        padding: 2rem;

        .col-12 {
            padding: 1rem;
        }

        .feature-card {
            background-color: var(--surface-e);
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
            height: 100%;
            border-radius: 3px;

            .feature-card-detail {
                padding: 0 2rem 2rem 2rem;
            }

            p {
                margin-bottom: 0;
            }
        }

        img {
            width: 100%;
        }

        .feature-name {
            font-weight: 700;
            font-size: 16px;
            margin: 1rem 0;
        }
    }

    .whouses {
        background-color: #34495E;
        color: #EAECEE;
        text-align: center;
        padding: 2rem;

        img {
            height: 30px;
            width: 100%;
        }

        .grid>div {
            padding: 2rem;
        }
    }

    .templates {
        background-color: var(--surface-b);
        text-align: center;
        padding: 2rem;

        img {
            width: 100%;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
        }
    }

    .why-templates {
        text-align: left;
        padding-top: 2rem;

        img {
            box-shadow: none;
        }
    }

    .primeng-designer {
        background-color: var(--surface-a);
        color: var(--text-color);
        padding: 2rem;

    }

    .lts {
        background-color: var(--surface-b);
        color: var(--text-color);
        padding: 2rem;

        h4 {
            text-align: center;
        }
    }

    .primeflex {
        background-color: var(--surface-a);
        color: var(--text-color);
        padding: 2rem;
    }

    .primeblocks {
        background-color: var(--surface-b);
        color: var(--text-color);
        padding: 2rem;
    }

    .prosupport {
        border-bottom: 1px solid var(--surface-d);
        background-color: var(--surface-a);
        padding: 2rem;
        color: var(--text-color);

        .action-button {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}