.layout-news {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    padding: 0 2rem;

    .layout-news-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 0%;
    }

    .layout-news-text {
        line-height: 1.5;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0%;
    }

    .layout-news-link {
        margin-left: .5rem;
        line-height: 1.5;
        white-space: nowrap;

        &:hover {
            text-decoration: underline;
        }
    }

    .layout-news-close {
        line-height: 1.5;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        margin-left:.5rem;
        transition: background-color .3s;

        &:hover {
            background-color: rgba(255,255,255,.2);
        }
    } 
}

.landing-news-active {
    .landing-header {
        top: 2rem;
    }
}

.layout-news-active {
    .layout-sidebar,
    .layout-topbar,
    .layout-config {
        top: 2rem;
    }

    .layout-content {
        padding-top: 7rem;
    }
}