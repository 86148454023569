.layout-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--surface-overlay);
    width: 250px;
    user-select: none;
    transition: transform .4s cubic-bezier(.05,.74,.2,.99);
    display: flex;
    flex-direction: column;

    .logo {
        min-height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        &:focus {
            outline: 0 none;
            transition: box-shadow .2s;
            box-shadow: 0 0 0 0.2rem $focusBorderColor;
        }
    }

    .layout-menu {
        padding: 0 2rem 2rem 2rem;
        overflow-y: auto;
        flex-grow: 1;

        .menu-category {
            display: flex;
            align-items: center;
            color: var(--surface-900);
            font-weight: 700;
            user-select: none;
            padding: .5rem .25rem;
            font-size: 0.857rem;
            text-transform: uppercase;

            .layout-menu-badge {
                padding: .14rem .5rem;
                font-size: .7rem;
                line-height: 1.4;
            }
        }

        .menu-items {
            padding: 0 0 1rem 0;
            display: flex;
            flex-direction: column;

            a {
                color: var(--text-color);
                display: flex;
                align-items: center;
                padding: .5rem .75rem;
                border-radius: 10px;
                cursor: pointer;
                
                .layout-menu-badge {
                    padding: .14rem .5rem;
                    font-size: .7rem;
                    line-height: 1.4;
                }

                &:hover {
                    background-color: var(--surface-hover);
                }

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: var(--focus-ring);
                }

                &.router-link-exact-active {
                    font-weight: 700;
                    color: var(--primary-color);
                }

                .p-tag {
                    padding-top:.125rem;
                    padding-bottom: .125rem;
                    margin-left: .5rem;
                }
            }

            div {
                &.router-link-exact-active {
                    > a {
                        font-weight: 700;
                    }
                    
                    ul {
                        display: block;
                    }
                }

                ul {
                    padding: .5rem 0;
                    margin: 0;
                    list-style-type: none;

                    a {
                        font-size: .875rem;
                        padding: .475rem .5rem .475rem 2rem;
                    }
                }
            }
        }

        .menu-image {
            padding: 0 0 1rem 0;
            
            img {
                width: 100%;
            }
        }
    }

    .layout-sidebar-filter {
        padding: 1rem 2rem 2rem;
    }
}