/* You can add global styles to this file, and also import other style files */

/* Custom Font */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap");

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

/* Ag grid theming */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~animate.css/animate.css";

@import "assets/showcase/styles/primeng.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.css";
// @import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/quill/dist/quill.snow.css";

// @import "./assets/showcase/styles/app/app.scss";
$gutter: 1rem;

@import "../node_modules/prismjs/themes/prism-coy.css";

/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
* {
  font-family: "Montserrat", sans-serif;
}

body {
  padding-left: 0;
  padding-right: 0;
}

/* @media print {
  html,
  body {
    display: none; 
    hide whole page
  }
} */

::ng-deep body .p-table .p-paginator {
  border: 0 none !important;
  padding: 0.7em !important;
}

.horizantal-rule {
  margin-left: 10%;
  width: 80%;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.pointer {
  cursor: pointer !important;
}

.p-menu {
  width: 100% !important;
}

body .p-menu {
  border: none !important;
}

.ag-grid-table {
  height: calc(100vh - 110px);
  /*width: 80%;*/
}

/* Cancel icon for dialog */
body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  /* margin-left: 39% !important; */
  /* margin-left: 77% !important; */
  /* -webkit-transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s; */
}

body .grid-view-dialog .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  /* margin-left: 39% !important; */
  /* margin-left: 39% !important; */
  /* -webkit-transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s; */
}

body .add-item-dialog .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  /* margin-left: 39% !important; */
  /* margin-left: 39% !important; */
  /* -webkit-transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s; */
}

/* //home view */
.p-dataview.p-dataview-list > .p-dataview-content > div.p-g > div {
  width: 100%;
}

.margin-top {
  margin-top: 0.5em !important;
}

/* body .p-panel .p-panel-content {
  height: 15.5em;
} */

.capitalize {
  text-transform: capitalize;
}

/* body .p-dropdown {
  text-transform: capitalize;
} */

/*Steps Styling*/
/* body .p-steps{
    position: fixed !important;
    overflow: hidden;
} */
/* Pick LIst Button */
/* body .p-picklist .p-picklist-buttons .p-picklist-buttons-cell .p-picklist-button :nth-child(2),:nth-child(4) {
        display: none !important;
    } */

html,
body {
  height: 100%;
  // line-height: 0.1rem;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Style Applied in Legends in Split Dashboard */
.yellowC {
  background: #fbc138 !important;
}

.orangeC {
  background: #f58038 !important;
}

.parrot {
  background: #c0ca33 !important;
}

.lightGreen {
  background: #689f38 !important;
}

.greenish {
  background: #2e7d32 !important;
  color: white !important;
}

/* End of Legend Css */

/* width */
::ng-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  scrollbar-color: #330867 #f5f5f5;
  background-color: #f5f5f5;
}

/* Track */
::ng-deep ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Handle */
::ng-deep ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-linear-gradient(bottom, #30cfd0 0%, #330867 100%);
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

html {
  overflow-y: hidden !important;
}

.addedCommentIcon {
  color: #17ce63;
}

.modal-dialog.modal-notify.modal-info .modal-header {
  background-color: #3db685;
}

.modal-dialog.modal-notify.modal-info .fa {
  color: white !important;
}

.modal-content {
  border: 0px solid rgba(0, 0, 0, 0.2);
}

.modal-body {
  padding: 0;
  margin-top: 1.3rem;
}

#photo-and-comment-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.comment-time-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.comment-time-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.time-left {
  font-size: 94%;
  padding-left: 10%;
  padding-bottom: 5%;
}

.time-right {
  font-size: 94%;
  padding-bottom: 5%;
  margin-left: 16%;
}

#photo-and-comment-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#photo-and-comment-it {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.align {
  margin-left: 75%;
}

.card {
  margin-bottom: 5%;

  width: fit-content;
  height: fit-content;
}

.bgc-admin {
  background: #213f81 !important;
}

.avatar {
  border-radius: 50%;
}

.img {
  height: 30px;
}

.bgc-user {
  background: #3db685 !important;
}

::ng-deep p-dialog .p-widget-overlay {
  background-color: rgb(0 0 0 / 77%);
}

.p-button {
  font-size: 0.8rem !important;
  // color: white !important;
  // background: #007ad9 !important;
  // padding: 0.4rem 0.7rem !important;
}

.p-compnent {
  font-size: small !important;
}

.p-dialog-title {
  font-size: 0.75rem !important;
}

.p-dialog-header {
  padding: 0.5rem 1rem !important;
}

.p-datatable-sm {
  font-size: small;
}

.mat-option {
  font-size: 14px !important;
}

th,
td {
  text-align: center;
  font-size: 12px;
}

.normalize-font {
  font-size: small !important;
}

label {
  font-size: small;
}

.form-control {
  font-size: small !important;
}

.p-card-body {
  padding: 0.75rem !important;
}

.p-card-content {
  font-size: small !important;
  padding: 0rem !important;
}

.p-inputtext {
  font-size: small !important;
  /* padding: 0.25rem 0.5rem !important; */
}

.p-dropdown-panel {
  font-size: small !important;
}

.p-dropdown-header {
  padding: 0.5rem 1rem !important;
}

.p-datatable-thead > tr > th {
  // background-color:#dee2e6 !important;
  white-space: break-spaces !important;
  text-align: center !important;
  justify-content: center;
}

.p-datatable-tbody > tr > td {
  white-space: break-spaces !important;
  text-align: center !important;
  justify-content: center;
}

/* p{
  margin-top: 0!important;
  margin-bottom: 0 !important;
} */

.p-multiselect-label {
  font-size: small;
}

.p-tabview-panels {
  margin-bottom: 1rem !important;
  padding: 0.5rem !important;
}

.p-multiselect-items {
  font-size: small !important;
}

.p-frozen-column {
  z-index: 1 !important;
}

.p-tooltip-text {
  font-size: small !important;
}

.navbar {
  padding: 0rem 1rem !important;
}

.p-datatable-header {
  padding: 0.25rem 0.5rem !important;
}

.alert {
  font-size: small;
}

.modal-content {
  font-size: small !important;
}

.p-toast {
  font-size: small !important;
}

.p-steps ul {
  font-size: small;
}

.p-steps-item {
  padding: 0.5rem;
}

.p-button-icon {
  font-size: 0.8rem !important;
}

// ::ng-deep  .gallary-dialog .p-dialog-draggable .p-dialog-header {
//   display: initial !important;
// }

.normalize-font {
  font-size: small;
}

.picker-dialog-bg {
  z-index: 3000000000  !important;
}

.picker-dialog {
  z-index: 3000000000  !important;
}

.p-button.p-button-icon-only {
  // width: 2.5rem !important;
  // height: 2.5rem !important;
}

::ng-deep ngx-doc-viewer .container {
  height: 65% !important;
}

.p-datatable-thead > tr > th {
  background: #eff3f8 !important;
}

.no-records-found-msg {
  padding: 0.5rem;
  color: red;
  font-weight: 500;
  font-size: 1rem;
  display: flex !important;
  justify-content: start !important;
}

.confirmation-yes {
  background-color: #16a34a !important;
}
.confirmation-no {
  background-color: #64748b !important;
}
.confirmation-yes:hover {
  background-color: #118d3f !important;
}
.confirmation-no:hover {
  background-color: #546071 !important;
}
.p-toast-icon-close {
  overflow: visible !important;
}