.layout-footer {
    padding: 2rem;
    font-size: 1rem;
    background-color: var(--surface-ground);
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        color: var(--text-color);
        font-weight: 600;
    }

    .layout-footer-right {
        display: flex;
        a {
            i {
                color: var(--text-secondary-color);
                font-size: 1.5rem;
            }
        }
    }
}